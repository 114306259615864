import {Button} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Alert, AlertTitle} from '@material-ui/lab';
import {ApolloError} from 'apollo-client';
import {FC} from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

interface GraphQLErrorsProps {
    error: ApolloError;
    refetch?: () => any;
}

export const GraphQLErrors: FC<GraphQLErrorsProps> = ({error, refetch}) => {
    const classes = useStyles();

    const refetchButton = refetch && (
        <Button color='inherit' size='small' onClick={() => refetch()}>
            Retry
        </Button>
    );

    return (
        <div className={classes.root}>
            {error.graphQLErrors.map(({message}) => (
                <Alert key={message} severity='error' action={refetchButton}>
                    <AlertTitle>GraphQL error</AlertTitle>
                    {message}
                </Alert>
            ))}
            {error.networkError && (
                <Alert severity='error' action={refetchButton}>
                    <AlertTitle>Network error</AlertTitle>
                    {error.networkError.message}
                </Alert>
            )}
        </div>
    );
};

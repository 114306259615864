type BaseError = {
    statusCode: number;
    message: string;
};

export class BadRequest extends Error implements BaseError {
    statusCode: number = 400;

    constructor(message?: string) {
        super(message || 'Bad request');
        Object.setPrototypeOf(this, BadRequest.prototype);
    }
}

export class Unauthorized extends Error implements BaseError {
    statusCode: number = 401;

    constructor(message?: string) {
        super(message || 'Unauthorized');
        Object.setPrototypeOf(this, Unauthorized.prototype);
    }
}

export class Forbidden extends Error implements BaseError {
    statusCode: number = 403;

    constructor(message?: string) {
        super(message || 'Forbidden');
        Object.setPrototypeOf(this, Forbidden.prototype);
    }
}

export class NotFound extends Error implements BaseError {
    statusCode: number = 404;

    constructor(message?: string) {
        super(message || 'Not found');
        Object.setPrototypeOf(this, NotFound.prototype);
    }
}

export class MethodNotAllowed extends Error implements BaseError {
    statusCode: number = 405;

    constructor(message?: string) {
        super(message || 'Method not allowed');
        Object.setPrototypeOf(this, MethodNotAllowed.prototype);
    }
}

export class InternalServerError extends Error implements BaseError {
    statusCode: number = 500;

    constructor(message?: string) {
        super(message || 'Internal server error');
        Object.setPrototypeOf(this, InternalServerError.prototype);
    }
}

import {CircularProgress} from '@material-ui/core';
import {GraphQLErrors} from 'core/components/graphql-errors';
import {Forbidden} from 'core/exceptions';
import {useGetUserQuery, UserFragment} from 'generated/graphql';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import * as settings from 'settings';

type UserCheck = (user: UserFragment) => boolean;

export const userCheckRequired = (check: UserCheck) => (Component: React.ComponentType) => () => {
    const router = useRouter();
    const {data, loading, error, refetch} = useGetUserQuery();

    useEffect(() => {
        if (!loading && !error) {
            if (!data?.user) {
                router.push(`${settings.LOGIN_URL}?next=${router.asPath}`);
            } else if (!check(data.user)) {
                throw new Forbidden();
            }
        }
    }, [data, loading, error]);

    if (error) return <GraphQLErrors error={error} refetch={refetch} />;
    if (!data?.user && loading) return <CircularProgress color='primary' />;
    return data?.user ? <Component /> : null;
};

export const loginRequired = userCheckRequired(user => Boolean(user));

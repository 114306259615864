import {loginRequired} from 'core/auth';
import {NextPage} from 'next';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

const Index: NextPage = () => {
    const router = useRouter();

    let startURL = '/home';

    useEffect(() => {
        router.push(startURL);
    }, []);

    return null;
};

export default loginRequired(Index);
